<template>
  <vx-card>
    <div class="vx-row">
      <!-- Cloture -->
      <vs-popup title="CLÔTURE CAISSE DEPENSE" :active.sync="popupCloture">
        <div class="vx-row">
          <div class="vx-col w-full">
            <vx-card :noShadow="true" :cardBorder="true">
              <div class="vx-row">
                <div class="vx-col bg-primary mr-2">
                  <h5 style="color: white">EN CAISSE</h5>
                </div>
                <div class="vx-col bg-black mr-2">
                  <h5 style="color: white">{{moneyFormatter((getApproMontant - getDepenseMontant))}}</h5>
                </div>
              </div>
              <div class="vx-row mt-2">
                <div class="vx-col w-full bg-primary text-center">
                  <span style="color: white">BILLETAGE</span>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="vx-row">
                    <div class="vx-col w-full mt-4">
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 text-right pt-2">
                          10000 X
                        </div>
                        <div class="vx-col md:w-2/3">
                          <div class="vx-row">
                            <div class="vx-col md:w-1/2">
                              <vs-input-number v-model="nbreDixMil" @change="totalDixMil" />
                            </div>
                            <div class="vx-col md:w-1/2 pt-2">
                              = {{moneyFormatter(MontDixMil)}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 text-right pt-2">
                          5000 X
                        </div>
                        <div class="vx-col md:w-2/3">
                          <div class="vx-row">
                            <div class="vx-col md:w-1/2">
                              <vs-input-number v-model="nbreCqMil" @change="totalCqMil" />
                            </div>
                            <div class="vx-col md:w-1/2 pt-2">
                              = {{moneyFormatter(MontCqMil)}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 text-right pt-2">
                          2000 X
                        </div>
                        <div class="vx-col md:w-2/3">
                          <div class="vx-row">
                            <div class="vx-col md:w-1/2">
                              <vs-input-number v-model="nbreDeuxMil" @change="totalDeuxMil" />
                            </div>
                            <div class="vx-col md:w-1/2 pt-2">
                              = {{moneyFormatter(MontDeuxMil)}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 text-right pt-2">
                          1000 X
                        </div>
                        <div class="vx-col md:w-2/3">
                          <div class="vx-row">
                            <div class="vx-col md:w-1/2">
                              <vs-input-number v-model="nbreMil" @change="totalMil" />
                            </div>
                            <div class="vx-col md:w-1/2 pt-2">
                              = {{moneyFormatter(MontMil)}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 text-right pt-2">
                          500 X
                        </div>
                        <div class="vx-col md:w-2/3">
                          <div class="vx-row">
                            <div class="vx-col md:w-1/2">
                              <vs-input-number v-model="nbreCqCnt" @change="totalCqCnt" />
                            </div>
                            <div class="vx-col md:w-1/2 pt-2">
                              = {{moneyFormatter(MontCqCnt)}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 text-right pt-2">
                          250 X
                        </div>
                        <div class="vx-col md:w-2/3">
                          <div class="vx-row">
                            <div class="vx-col md:w-1/2">
                              <vs-input-number v-model="nbreDeuxCntCq" @change="totalDeuxCntCqt" />
                            </div>
                            <div class="vx-col md:w-1/2 pt-2">
                              = {{moneyFormatter(MontDeuxCntCq)}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 text-right pt-2">
                          200 X
                        </div>
                        <div class="vx-col md:w-2/3">
                          <div class="vx-row">
                            <div class="vx-col md:w-1/2">
                              <vs-input-number v-model="nbreDeuxCnt" @change="totalDeuxCnt" />
                            </div>
                            <div class="vx-col md:w-1/2 pt-2">
                              = {{moneyFormatter(MontDeuxCnt)}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 text-right pt-2">
                          100 X
                        </div>
                        <div class="vx-col md:w-2/3">
                          <div class="vx-row">
                            <div class="vx-col md:w-1/2">
                              <vs-input-number v-model="nbreCnt" @change="totalCnt" />
                            </div>
                            <div class="vx-col md:w-1/2 pt-2">
                              = {{moneyFormatter(MontCnt)}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 text-right pt-2">
                          50 X
                        </div>
                        <div class="vx-col md:w-2/3">
                          <div class="vx-row">
                            <div class="vx-col md:w-1/2">
                              <vs-input-number v-model="nbreCqt" @change="totalCqt" />
                            </div>
                            <div class="vx-col md:w-1/2 pt-2">
                              = {{moneyFormatter(MontCqt)}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 text-right pt-2">
                          25 X
                        </div>
                        <div class="vx-col md:w-2/3">
                          <div class="vx-row">
                            <div class="vx-col md:w-1/2">
                              <vs-input-number v-model="nbreVingtCinq" @change="totalVingtCinq" />
                            </div>
                            <div class="vx-col md:w-1/2 pt-2">
                              = {{moneyFormatter(MontVingtCinq)}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 text-right pt-2">
                          10 X
                        </div>
                        <div class="vx-col md:w-2/3">
                          <div class="vx-row">
                            <div class="vx-col md:w-1/2">
                              <vs-input-number v-model="nbreDix" @change="totalDix" />
                            </div>
                            <div class="vx-col md:w-1/2 pt-2">
                              = {{moneyFormatter(MontDix)}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 text-right pt-2">
                          5 X
                        </div>
                        <div class="vx-col md:w-2/3">
                          <div class="vx-row">
                            <div class="vx-col md:w-1/2">
                              <vs-input-number v-model="nbreCinq" @change="totalCinq" />
                            </div>
                            <div class="vx-col md:w-1/2 pt-2">
                              = {{moneyFormatter(MontCinq)}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </vx-card>
            <vx-card :noShadow="true" :cardBorder="true" class="mt-2">
              <div class="vx-row">
                <div class="vx-col md:w-1/3 text-right">
                  TOTAL
                </div>
                <div class="vx-col md:w-2/3 bg-black text-right">
                  <h4 style="color: white"> {{moneyFormatter(totalCaisse)}} </h4>
                </div>
              </div>
              <div class="vx-row mt-2">
                <div class="vx-col md:w-1/3 text-right">
                  ECART
                </div>
                <div class="vx-col md:w-2/3 bg-black text-right">
                  <h4 style="color: white">{{moneyFormatter(((getApproMontant - getDepenseMontant) - totalCaisse))}}</h4>
                </div>
              </div>
            </vx-card>
          </div>
        </div>
        <!-- Save & Reset Button -->
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-8 flex flex-wrap items-center justify-end">
              <vs-button v-if="(((+getApproMontant - +getDepenseMontant)- totalCaisse) === 0)" class="ml-auto mt-2" @click="makeClotureCaisseDepense">CLOTURE</vs-button>
              <vs-button class="ml-4 mt-2" type="border" color="warning" @click="resetClotureData">ANNULER</vs-button>
            </div>
          </div>
        </div>

      </vs-popup>
      <vs-popup title="APPROVISIONNEMENT" :buttonCloseHidden="true" :active.sync="popopAppro">
        <div class="vx-row">
          <div class="vx-col w-full mt-5">
            <span style="font-weight: bold;">NB:</span> <span style="font-style: italic;background-color: #ECF8ED;">Les champs marqués du signe * sont obligatoires pour la sauvegarde de l'approvisionnement.</span>
          </div>
          <div class="vx-col w-full mt-5">
            <vs-select label="Origine*" autocomplete class="selectExample w-full select-large" v-model="Origine">
              <vs-select-item :key="index" :value="item" :text="item" v-for="(item,index) in ['Recette','Banque','Autre']" />
            </vs-select>
          </div>
          <div class="vx-col md:w-1/2">
            <vs-select label="Mode de payment*" autocomplete class="selectExample w-full select-large" v-model="MoyPaiement">
              <vs-select-item :key="index" :value="item.libelle" :text="item.value" v-for="(item,index) in moyen_paiement" />
            </vs-select>
          </div>
          <div class="vx-col md:w-1/2">
            <vs-input class="w-full"  type="text" label="Numéro de chèque"  name="NumCheque"  :value="NumCheque" @change.native="NumCheque=$event.target.value" />
          </div>
          <div class="vx-col md:w-1/2">
            <vs-input class="w-full" type="text" label="Motif*"  name="Motif" :value="Motif" @change.native="Motif=$event.target.value" />
          </div>
          <div class="vx-col md:w-1/2">
            <vs-input class="w-full" type="number" label="Montant*"  name="Montant" :value="Montant" @change="Montant=$event.target.value" />
          </div>
        </div>
        <!-- Save & Reset Button -->
        <div class="vx-row">
          <div class="vx-col w-full text-center">
            <div class="mt-8">
              <vs-button class="ml-auto mt-2" :disabled="!validateFormAppro" @click="approCaisseDepnse">ENREGISTRER</vs-button>
              <vs-button class="ml-4 mt-2" type="border" color="warning" @click="Montant=0;Motif='';NumCheque='';MoyPaiement=null;Origine='';popopAppro=false;">ANNULER</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
      <vs-popup title="INITIALISATION DE LA CAISSE DEPENSE" :active.sync="popupformInitialisationCaisse">
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-input class="w-full" type="number" label="Montant"  name="montantInitialisationCaisse" v-model="montantInitialisationCaisse" />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-4 flex flex-wrap items-center justify-center">
              <vs-button class="ml-auto mt-2" v-if="montantInitialisationCaisse" @click="initialisationCaisse">INITALISER</vs-button>
              <vs-button class="ml-4 mt-2" type="border" color="warning" @click="montantInitialisationCaisse = 0;popupformInitialisationCaisse=false">ANNULER</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
      <div class="vx-col w-full">
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="vx-row">
              <div class="vx-col">
                <vs-input type="date"  v-model="dateCaisse" @change="gotToSelectOperationOfDateSelected()" :max="new Date(Date.now()).toISOString().split('T')[0]"></vs-input>
              </div>
              <div class="vx-col">
                <vs-select v-if="principale" placeholder="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="IdOfficine" @change="SelectOperationOfDateSelected()">
                  <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
                </vs-select>
              </div>
              <div class="vx-col" v-if="CaisseIsInit">
                <vs-button v-if="!jourFermer&&!billetageOnSelectDay" @click="popopAppro=true">APPROVISIONNEMENT</vs-button>
              </div>
              <div class="vx-col" v-if="CaisseIsInit">
                <vs-button v-if="!jourFermer&&!billetageOnSelectDay" @click="popupCloture=true">CLÔTURE CAISSE DEPENSE</vs-button>
              </div>
            </div>
          </div>
          <!-- <div class="vx-col md:w-1/2 text-right">
            {{timestamp | moment("HH:mm:ss") }} (UTC)
          </div> -->
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-alert :active="jourFermer || jourSelectedIsFerier" class="mt-5" color="danger" icon-pack="feather" icon="icon-info">
              Impossible d'initialiser la caisse depense a la date du {{this.dateCaisse | moment("calendar", "July 10 2011")}}: jour non ouvrable
            </vs-alert>
            <vs-alert :active="!CaisseIsInit" class="mt-5" color="warning" icon-pack="feather" icon="icon-info">
              La caisse depense du {{this.dateCaisse | moment("calendar", "July 10 2011")}} n'est  pas initialisée. Voulez-vous l'initialisé avec la cloture de caisse du  {{getYesterdayDate | moment("calendar", "July 10 2011")}} ?
            </vs-alert>
          </div>
        </div>
        <div class="vx-row mt-2" v-if="!CaisseIsInit">
          <template v-if="!jourSelectedIsFerier">
            <div class="vx-col">
              <vs-button v-if="!jourFermer" color="primary" class="w-full" @click="initialisationCaisseDepenseAvecClotureCaisse">Initialisation avec clôture</vs-button>
            </div>
          </template>
          <!-- <div class="vx-col">
            <vs-button v-if="!jourFermer" color="danger" class="w-full" @click="showFormInitialisationCaisse">Autre</vs-button>
          </div> -->
        </div>
        <vs-divider />
        <div class="vx-row">
          <div class="vx-col w-full" v-if="!billetageOnSelectDay">
            <vx-card :noShadow="true" :cardBorder="true">
              <div class="vx-row">
                <div class="vx-col w-full mt-5">
                  <span style="font-weight: bold;">NB:</span> <span style="font-style: italic;background-color: #ECF8ED;">Les champs marqués du signe * sont obligatoires pour la sauvegarde de l'opération.</span>
                </div>
              </div>
              <div class="vx-row mt-5">
                <div class="vx-col md:w-1/2">
                  <vs-input class="w-full" type="text" label="Bénéficiaire*"  name="Beneficiaire" :value="Beneficiaire" @change.native="Beneficiaire=$event.target.value" />
                  <span class="text-danger text-sm"  v-show="errors.has('Beneficiaire')">{{ errors.first('Bénéficiaire') }}</span>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Mode de payment*" autocomplete class="selectExample w-full select-large" v-model="MoyPaiement">
                    <vs-select-item :key="index" :value="item.libelle" :text="item.value" v-for="(item,index) in moyen_paiement" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Rubrique dépense*" autocomplete class="selectExample w-full select-large" v-model="IdRubriqueDepense">
                    <vs-select-item :key="index" :value="item._id" :text="item.Libelle" v-for="(item,index) in rubriques" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-input class="w-full"  type="text" label="Numéro de chèque"  name="NumCheque"  :value="NumCheque" @change.native="NumCheque=$event.target.value" />
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-input class="w-full" type="text" label="Motif*"  name="Motif" :value="Motif" @change.native="Motif=$event.target.value" />
                  <span class="text-danger text-sm"  v-show="errors.has('Motif')">{{ errors.first('Motif') }}</span>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-input class="w-full" type="number" label="Montant*"  name="Montant" :value="Montant" @change="Montant=$event.target.value" />
                  <span class="text-danger text-sm"  v-show="errors.has('Montant')">{{ errors.first('Montant') }}</span>
                </div>
              </div>
              <!-- Save & Reset Button -->
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="mt-8 flex flex-wrap items-center justify-end">
                    <vs-button v-if="CaisseIsInit" :disabled="!validateFormOperation" class="ml-auto mt-2" @click="makeDepense">ENREGISTRER</vs-button>
                    <vs-button v-if="CaisseIsInit" class="ml-4 mt-2" type="border" color="warning" @click="Montant=0;Motif='';NumCheque='';MoyPaiement=null;Password='';Beneficiaire='';">ANNULER</vs-button>
                  </div>
                </div>
              </div>
            </vx-card>
          </div>
          <div class="vx-col w-full mt-2" >
            <vx-card :noShadow="true" :cardBorder="true">
              <vs-table stripe pagination max-items="10" search :data="depenseData" noDataText="Aucune opération">
                <template slot="thead">
                  <vs-th>
                    N°
                  </vs-th>
                  <vs-th sort-key="DateOp">Date</vs-th>
                  <vs-th sort-key="HeurOp">Heure</vs-th>
                  <!-- <vs-th sort-key="IdUser">Agent</vs-th> -->
                  <vs-th sort-key="Beneficiaire">Bénéficiaire</vs-th>
                  <vs-th sort-key="MontantDepense">Montant</vs-th>
                  <vs-th sort-key="Motif">Motif</vs-th>
                  <!-- <vs-th sort-key="Mouvement">Mouvement</vs-th> -->
                </template>

                <template slot-scope="{data}">
                  <tbody>
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                      <vs-td>
                        {{indextr + 1}}
                      </vs-td>
                      <vs-td>
                        {{tr.DateOp | moment("calendar", "July 10 2011") }}
                      </vs-td>
                      <vs-td>
                        {{tr.createdAt | moment("HH:mm:ss")}}
                      </vs-td>
                      <vs-td>
                        {{tr.Beneficiaire}}
                      </vs-td>
                      <vs-td>
                        {{moneyFormatter(tr.Montant)}}
                      </vs-td>
                      <vs-td>
                        {{tr.Motif}}
                      </vs-td>

                    </vs-tr>
                  </tbody>
                </template>
              </vs-table>
            </vx-card>
          </div>
          <div class="vx-col w-full mt-2">
            <vx-card :noShadow="true" :cardBorder="true">
              <div class="vx-row mt-1">
                <div class="vx-col md:w-1/4 text-center">
                  <h5 class="bg-primary p-1" style="color:white">Mouvement du jour</h5>
                </div>
                <div class="vx-col md:w-1/4 mt-1">
                  <h5 class="bg-black p-1" style="color: white">Encaissé: {{moneyFormatter(getApproMontant)}} </h5>
                </div>
                <div class="vx-col md:w-1/4 mt-1">
                  <h5 class="bg-black p-1" style="color: white">Décaissé: {{moneyFormatter(getDepenseMontant)}} </h5>
                </div>
                <div class="vx-col md:w-1/4 mt-1">
                  <h5 class="p-1" :class="{'bg-black': enCaisseSupToZera, 'bg-red': enCaisseInfToZero}" style="color: white">En caisse: {{moneyFormatter((getApproMontant - getDepenseMontant))}} </h5>
                </div>
              </div>
            </vx-card>
          </div>

        </div>

      </div>
    </div>

  </vx-card>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import VueHtml2pdf from 'vue-html2pdf'
export default {
  name: 'caisse_depense',
  components: {
    flatPickr,
    VueHtml2pdf
  },
  data () {
    return {
      activeUserInfos: null,
      IdOfficine: null,
      IdUser: null,
      popopAppro: false,
      popupCloture: false,
      moyen_paiement:
      [
        {libelle: 'Espèce', value: 'Espèce'},
        {libelle: 'Chèque', value: 'Chèque'},
        {libelle: 'Virement bancaire', value: 'Virement bancaire'},
        {libelle: 'Transfert', value: 'Transfert'}
      ],

      /* caisse data */
      dateCaisse: new Date(Date.now()).toISOString().split('T')[0],
      /* time data */
      timestamp: '',
      /* depense data */
      Beneficiaire: '',
      Montant: 0,
      Motif: '',
      MoyPaiement: '',
      Numero: '',
      NumCheque: '',
      Password: '',
      IdRubriqueDepense: null,
      /* appro */
      Origine: '',
      /* cloture */
      popupformInitialisationCaisse: false,
      //jourFermer: false,
      Observation: '',
      TotalEnCaisse: 0,
      nbreDixMil: 0,
      nbreCqMil: 0,
      nbreDeuxMil: 0,
      nbreMil: 0,
      nbreCqCnt: 0,
      nbreDeuxCntCq: 0,
      nbreDeuxCnt: 0,
      nbreCnt: 0,
      nbreCqt: 0,
      nbreVingtCinq: 0,
      nbreDix: 0,
      nbreCinq: 0,
      MontDixMil: 0,
      MontCqMil: 0,
      MontDeuxMil: 0,
      MontMil: 0,
      MontCqCnt: 0,
      MontDeuxCntCq: 0,
      MontDeuxCnt: 0,
      MontCnt: 0,
      MontCqt: 0,
      MontVingtCinq: 0,
      MontDix: 0,
      MontCinq: 0,
      MontCheqRecu: 0,
      MontCeqEmis: 0,

      montantInitialisationCaisse: null
    }
  },
  computed: {
    validateFormOperation () {
      return !this.errors.any() && (this.Beneficiaire !== '' && this.Beneficiaire !== null) && (this.Montant !== null && this.Montant !== '') && (this.Motif !== '' && this.Motif !== null) && (this.MoyPaiement !== '' && this.MoyPaiement !== null) && (this.IdRubriqueDepense !== '' && this.IdRubriqueDepense !== null)
    },
    validateFormAppro () {
      return !this.errors.any() && (this.Origine !== '' && this.Origine !== null) && (this.Montant !== null && this.Montant !== '') && (this.Motif !== '' && this.Motif !== null) && (this.MoyPaiement !== '' && this.MoyPaiement !== null)
    },
    billetageOnSelectDay () {
      return this.$store.state.billetage_depense.billetage_depense
    },
    enCaisseInfToZero () {
      return (this.getApproMontant - this.getDepenseMontant) < 0
    },
    enCaisseSupToZera () {
      return (this.getApproMontant - this.getDepenseMontant) >= 0
    },
    CaisseIsInit () {
      return this.$store.getters['depense/CaisseIsInit']
    },
    officines () {
      return this.$store.state.officine.officines
    },
    rubriques () {
      return this.$store.state.rubrique_depense.rubriques
    },
    operationsToDayData () {
      return this.$store.state.operation.operationsToday
    },
    principale () {
      return this.$store.getters['officine/officineIsPrincipale'](JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null)
    },
    depenseData () {
      return this.$store.state.depense.depenses
    },
    approCaisseData () {
      return this.$store.state.depense.approCaisse
    },
    getApproMontant () {
      return this.$store.getters['depense/getApproMontant']
    },
    getDepenseMontant () {
      return this.$store.getters['depense/getDepenseMontant']
    },
    totalDixMil () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontDixMil = +this.nbreDixMil * 10000
      return this.MontDixMil
    },
    totalCqMil () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontCqMil = +this.nbreCqMil * 5000
      return this.MontCqMil
    },
    totalDeuxMil () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontDeuxMil = +this.nbreDeuxMil * 2000
      return this.MontDeuxMil
    },
    totalMil () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontMil = +this.nbreMil * 1000
      return this.MontMil
    },
    totalCqCnt () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontCqCnt = +this.nbreCqCnt * 500
      return this.MontCqCnt
    },
    totalDeuxCntCqt () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontDeuxCntCq = +this.nbreDeuxCntCq * 250
      return this.MontDeuxCntCq
    },
    totalDeuxCnt () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontDeuxCnt = +this.nbreDeuxCnt * 200
      return this.MontDeuxCnt
    },
    totalCnt () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontCnt = +this.nbreCnt * 100
      return this.MontCnt
    },
    totalCqt () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontCqt = +this.nbreCqt * 50
      return this.MontCqt
    },
    totalVingtCinq () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontVingtCinq = +this.nbreVingtCinq * 25
      return this.MontVingtCinq
    },
    totalDix () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontDix = +this.nbreDix * 10
      return this.MontDix
    },
    totalCinq () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.MontCinq = +this.nbreCinq * 5
      return this.MontCinq
    },
    totalCaisse () {
      return +this.MontDixMil + +this.MontCqMil + +this.MontDeuxMil + +this.MontMil + +this.MontCqCnt + +this.MontDeuxCntCq + +this.MontDeuxCnt + +this.MontCnt + +this.MontCqt + +this.MontVingtCinq + +this.MontDix + +this.MontCinq
    },
    jourSelectedIsFerier () {
      return this.$store.getters['configs/dayIsFerie'](this.dateCaisse)
    },
    jourFermer () {
      const idOfficine = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null
      const officineJoursOuvrable = this.$store.getters['officine/getOfficineJoursOuvrable'](idOfficine)
      const currentDate = new Date(this.dateCaisse)
      const currentDay = currentDate.getDay()

      return !officineJoursOuvrable.includes(currentDay)
    },
    getYesterdayDate () {
      const idOfficine = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null
      const officineJoursOuvrable = this.$store.getters['officine/getOfficineJoursOuvrable'](idOfficine)

      const currentDate = new Date(this.dateCaisse)
      //const jour = new Date(currentDate).getDay()
      const yesterday = new Date(currentDate)
      let iterations = 0
      // for(; ;) crée un boucle infinie
      while (iterations < 1000) { // Limite arbitraire pour éviter une boucle infinie
        yesterday.setDate(yesterday.getDate() - 1)
        const currentDay = yesterday.getDay()

        // Vérifiez si le jour est un jour ouvrable et non férié
        if (officineJoursOuvrable.includes(currentDay) && !this.$store.getters['configs/dayIsFerie'](yesterday)) {
          break
        }

        iterations++
      }

      return yesterday.toISOString().split('T')[0]
    }
  },
  methods: {
    showFormInitialisationCaisse () {
      this.popupformInitialisationCaisse = true
    },
    makeClotureCaisseDepense () {
      const payload = {

        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        DateCloture: new Date(this.dateCaisse).toISOString().split('T')[0],
        MontDixMil: this.MontDixMil,
        MontCqMil: this.MontCqMil,
        MontDeuxMil: this.MontDeuxMil,
        MontMil: this.MontMil,
        MontCqCnt: this.MontCqCnt,
        MontDeuxCntCqt: this.MontDeuxCntCqt,
        MontDeuxCnt: this.MontDeuxCnt,
        MontCnt: this.MontCnt,
        MontCqt: this.MontCqt,
        MontVingtCinq: this.MontVingtCinq,
        MontDix: this.MontDix,
        MontCinq: this.MontCinq,
        TotalEncaisse: this.TotalEnCaisse,
        Observation: this.Observation,
        MontCheqRecu: this.MontantCheqRecu,
        MontCeqEmis: this.MontantCheqEmis
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('billetage_depense/makeClotureCaisseDepense', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.getBilletageDepenseOnPeriode()
          /* this.resetClotureData() */
          this.popupCloture = false
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getBilletageDepenseOnPeriode () {
      const payload = {
        idOfficine: this.IdOfficine,
        DateCloture: this.dateCaisse
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('billetage_depense/getBilletageDepenseOnPeriode', payload)
        .then((response) => {
          /* this.Observation = response.data ? (response.data.Observation ? response.data.Observation : '') : '' */
          this.TotalEnCaisse = response.data ? (response.data.TotalEncaise ? response.data.TotalEncaise : 0) : 0
          this.MontDixMil = response.data ? (response.data.MontDixMil ? response.data.MontDixMil : 0) : 0
          this.MontCqMil = response.data ? (response.data.MontCqMil ? response.data.MontCqMil : 0) : 0
          this.MontDeuxMil = response.data ? (response.data.MontDeuxMil ? response.data.MontDeuxMil : 0) : 0
          this.MontMil = response.data ? (response.data.MontMil ? response.data.MontMil : 0) : 0
          this.MontCqCnt = response.data ? (response.data.MontCqCnt ? response.data.MontCqCnt : 0) : 0
          this.nbreDeuxCntCq = response.data ? (response.data.nbreDeuxCntCq ? response.data.nbreDeuxCntCq : 0) : 0
          this.MontDeuxCntCqt = response.data ? (response.data.MontDeuxCntCqt ? response.data.MontDeuxCntCqt : 0) : 0
          this.MontDeuxCnt = response.data ? (response.data.MontDeuxCnt ? response.data.MontDeuxCnt : 0) : 0
          this.MontCnt = response.data ? (response.data.MontCnt ? response.data.MontCnt : 0) : 0
          this.MontCqt = response.data ? (response.data.MontCqt ? response.data.MontCqt : 0) : 0
          this.MontVingtCinq = response.data ? (response.data.MontVingtCinq ? response.data.MontVingtCinq : 0) : 0
          this.MontDix = response.data ? (response.data.MontDix ? response.data.MontDix : 0) : 0
          this.MontCinq = response.data ? (response.data.MontCinq ? response.data.MontCinq : 0) : 0
          this.MontCqMil = response.data ? (response.data.MontCqMil ? response.data.MontCqMil : 0) : 0
          this.MontCheqRecu = response.data ? (response.data.MontCheqRecu ? response.data.MontCheqRecu : 0) : 0
          this.MontCeqEmis = response.data ? (response.data.MontCeqEmis ? response.data.MontCeqEmis : 0) : 0
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    resetClotureData () {},
    makeDepense () {
      if ((this.getApproMontant - this.getDepenseMontant) < this.Montant) {
        this.$vs.dialog({
          type: 'alert',
          color: 'danger',
          title: 'Error',
          text: 'Caisse insuffisante',
          buttonAccept: 'true'
        })
      } else {
        const payload = {
          IdOfficine: this.IdOfficine,
          IdUser: this.IdUser,
          DateOp: new Date(this.dateCaisse).toISOString().split('T')[0],
          Beneficiaire: this.Beneficiaire,
          Password: this.Password,
          IdRubriqueDepense: this.IdRubriqueDepense,
          Montant: this.Montant,
          Motif: this.Motif,
          MoyPaiement: this.MoyPaiement
        }

        this.$vs.loading({
          type: 'sound'
        })

        this.$store.dispatch('depense/makeDepense', payload)
          .then(() => {
            this.Beneficiaire = ''
            this.Password = ''
            this.Montant = 0
            this.Motif = ''
            this.MoyPaiement = null
            this.IdRubriqueDepense = null

            this.$vs.loading.close()
            this.$vs.notify({
              title: 'success',
              text: 'success',
              iconPack: 'feather',
              icon: 'icon-check',
              color: 'primary'
            })
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close()
          })
      }
    },
    approCaisseDepnse () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        DateOp: new Date(this.dateCaisse).toISOString().split('T')[0],
        Motif: this.Motif,
        MoyPaiement: this.MoyPaiement,
        Montant: this.Montant,
        Origine: this.Origine,
        NumCheque: this.NumCheque
      }

      this.$store.dispatch('depense/approCaisseDepnse', payload)
        .then(() => {
          this.$vs.loading.close()
          this.Motif = ''
          this.MoyPaiement = ''
          this.Montant = 0
          this.Origine = ''
          this.NumCheque = ''
          this.getOperationToDay()
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    initialisationCaisseDepenseAvecClotureCaisse () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        Motif: 'initialisation',
        DateClotureCaisse: this.getYesterdayDate,
        Origine: `caisse du :${this.getYesterdayDate}`,
        DateCaisse: new Date(this.dateCaisse).toISOString().split('T')[0]
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('depense/initialisationCaisseDepenseAvecClotureCaisse', payload)
        .then((resp) => {
          if (resp.data.message) {
            this.$vs.loading.close()
            this.$vs.dialog({
              type: 'alert',
              color: 'danger',
              title: 'Error',
              text: `Effectuer la cloture de caisse depense du: ${this.getYesterdayDate}`,
              buttonAccept: 'true'
            })
          } else {
            this.getOperationToDay()
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'success',
              text: 'success',
              iconPack: 'feather',
              icon: 'icon-check',
              color: 'primary'
            })

          }
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    initialisationCaisse () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        Motif: 'initialisation',
        Montant: this.montantInitialisationCaisse,
        Origine: 'Autre',
        DateCaisse: new Date(this.dateCaisse).toISOString().split('T')[0]
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('depense/initialisationCaisseDepense', payload)
        .then(() => {
          this.getOperationToDay()
          this.popupformInitialisationCaisse = false
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })


    },
    gotToSelectOperationOfDateSelected () {
      if (this.dateCaisse) {
        this.SelectOperationOfDateSelected()
      }
    },
    SelectOperationOfDateSelected () {
      const payload = {
        dateOp: new Date(this.dateCaisse).toISOString().split('T')[0],
        Officine: this.IdOfficine
      }
      /* console.log(`dateCaisse=> ${this.dateCaisse}`)
      console.log(`IdOfficine=> ${this.IdOfficine}`) */
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('depense/getApproOnSelectDate', payload)
        .then(() => {
          this.$store.dispatch('depense/getDepenseOnSelectDate', payload)
            .then(() => {
              this.getBilletageDepenseOnPeriode()
              this.$vs.loading.close()
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
            })
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getOperationToDay () {
      const payload = {
        dateOp: new Date(this.dateCaisse).toISOString().split('T')[0],
        Officine: this.IdOfficine
      }

      this.$store.dispatch('depense/getApproOnSelectDate', payload)
        .then(() => {

          this.$store.dispatch('depense/getDepenseOnSelectDate', payload)
            .then(() => {
              this.$vs.loading.close()
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
            })
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    /* times fonctions */
    getNow () {
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      const time = `${today.getHours()  }:${  today.getMinutes()  }:${  today.getSeconds()}`
      const dateTime = `${date } ${ time}`
      this.timestamp = dateTime
    },

    /* traitement caisse fonction */
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },
    getRubriques () {
      this.$store.dispatch('rubrique_depense/getRubriques')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.IdOfficine = this.activeUserInfos.IdOfficine
      this.IdUser = this.activeUserInfos._id
    }
  },
  created () {
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    this.getRubriques()
    this.activeUserInfo()
    this.getOperationToDay()
    /* setInterval(this.getNow, 1000) */
  }

}
</script>
<style>
.bg-red {
  background-color: rgb(230, 7, 7);
}
</style>
